import WasmController from "react-lib/frameworks/WasmController";
import DrugOrderDetailForAction from "issara-sdk/apis/DrugOrderDetailForAction_apps_TPD";
import DrugReturnRequestList from "issara-sdk/apis/DrugReturnRequestList_apps_TPD";
import DrugReturnRequestDetail from "issara-sdk/apis/DrugReturnRequestDetail_apps_TPD";
import DrugReturnRequestActionLogList from "issara-sdk/apis/DrugReturnRequestActionLogList_apps_TPD";
import UserLoginView from "issara-sdk/apis/UserLoginView_core";
import { SetErrorMessage } from "../../common/CommonInterface";

export type State = {
  errorMessage?: any;
  buttonLoadCheck?: any;
  ReturnOrderSequence?: {
    sequenceIndex?: string | null;
  } | null;
  OrderReturnList?: any;
  DrugReturnList?:
    | {
        code?: string | null;
        drug_order_item?: number | null;
        id?: number | null;
        name?: string | null;
        no?: number | null;
        quantity_discard?: number | null;
        quantity_issue?: number | null;
        quantity_left?: number | null;
        quantity_request?: number | null;
        quantity_restock?: number | null;
        reason?: string | null;
        stock_unit_name?: string | null;
      }[]
    | null;
  OrderHistory?: any;
  OrderReturnLog?: any;
};

export const StateInitial: State = {
  ReturnOrderSequence: null,
  OrderReturnList: [],
  DrugReturnList: null,
  OrderHistory: [],
  OrderReturnLog: [],
};

export type Event = { message: "RunSequence"; params: {} };
// | { messsage: "SelectDrugOrderWorking"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.ReturnOrderSequence) return;
  if (!params?.action) return;
  if (params.action === "RETURN_DRUG") {
    const orderHistory = await GetDrugReturnRequestList(controller, { order: params.id });

    controller.setState(
      {
        OrderHistory: orderHistory,
        ReturnOrderSequence: {
          sequenceIndex: "Edit",
        },
      },
      () => HandleNewOrder(controller, params)
    );
  }
};

export const Edit: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.ReturnOrderSequence) return;
  if (!params?.action) return;
  if (params?.action === "Edit") {
    const data = params.data;
    let items = state.DrugReturnList || [];

    const ItemsEdit = (item: any) => {
      items = items.map((acc: any, index: number) =>
        index === data.index ? { ...acc, ...item } : acc
      );
    };
    if (params.data.type === "quantity_request") {
      ItemsEdit({ quantity_request: data.value });
    }

    controller.setState({
      OrderReturnList: {
        ...state.OrderReturnList,
        items: items,
      },
      DrugReturnList: items,
    });
  } else if (params.action === "Request") {
    const btnKey = `${params.card}_${params.btnAction}`;

    // Feature 68258
    if (!params?.repassword) {
      SetErrorMessage(controller, {
        ...params,
        error: { error: { "กด คืนยา/เวชภัณฑ์ ไม่สำเร็จ": "จำเป็นต้องระบุรหัสผ่าน" } },
      });
      return;
    }
    const checkLogin = await UserLoginView.post({
      apiToken: controller.apiToken,
      data: {
        username: controller.data?.userProfile?.username,
        password: params?.repassword,
        userId: state.userId,
      },
    });

    if (checkLogin[1] && checkLogin[2]?.response?.status === 400) {
      SetErrorMessage(controller, {
        ...params,
        error: { error: { บันทึกไม่สำเร็จ: "กรุณาระบุรหัสผ่านให้ถูกต้อง" } },
      });
      return;
    }

    const isDrugExceeding = VerifyDrugsExceedIssuedQuantity(controller, params);

    if (isDrugExceeding) {
      return;
    }

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
    });

    const drug: any = state.DrugReturnList;

    const defaultData = (drug || [])?.map((item: any) => ({
      code: item.code,
      drug_order_item: item.id,
      name: item.name,
      no: item.no,
      quantity_discard: 0,
      quantity_issue: item.quantity_issue,
      quantity_left: item.quantity_left,
      quantity_request: item.quantity_request || 0,
      quantity_restock: 0,
      storekey: 0,
      stock_unit_name: item.stock_unit_name,
    }));

    const [result, error] = await DrugReturnRequestList.create({
      data: {
        action: "REQUEST",
        items: defaultData,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    if (error) {
      SetErrorMessage(controller, { ...params, error: { error } });
    } else {
      const orderHistory = await GetDrugReturnRequestList(controller, { order: result.order });

      controller.setState({
        OrderHistory: orderHistory,
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
      });
    }
  } else if (params?.action === "HistoryRequest") {
    const order = await DrugReturnRequestDetail.retrieve({
      pk: params?.orders?.pk,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    controller.setState({
      OrderReturnList: order?.[0],
      DrugReturnList: order?.[0]?.items,
    });
  } else if (params?.action === "New") {
    HandleNewOrder(controller, params);
  } else if (params?.action === "Log") {
    const orderLog = await DrugReturnRequestActionLogList.list({
      pk: params.id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    controller.setState({
      OrderReturnLog: orderLog?.[0],
    });
  } else if (params?.action === "Update") {
    const btnKey = `${params.card}_${params.btnAction}`;

    // Feature 68258
    if (!params?.repassword) {
      SetErrorMessage(controller, {
        ...params,
        error: { error: { "กด คืนยา/เวชภัณฑ์ ไม่สำเร็จ": "จำเป็นต้องระบุรหัสผ่าน" } },
      });
      return;
    }
    const checkLogin = await UserLoginView.post({
      apiToken: controller.apiToken,
      data: {
        username: controller.data?.userProfile?.username,
        password: params?.repassword,
        userId: state.userId,
      },
    });

    if (checkLogin[1] && checkLogin[2]?.response?.status === 400) {
      SetErrorMessage(controller, {
        ...params,
        error: { error: { บันทึกไม่สำเร็จ: "กรุณาระบุรหัสผ่านให้ถูกต้อง" } },
      });
      return;
    }

    const isDrugExceeding = VerifyDrugsExceedIssuedQuantity(controller, params);

    if (isDrugExceeding) {
      return;
    }

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
    });

    const [result, error] = await DrugReturnRequestDetail.update({
      apiToken: controller.apiToken,
      data: {
        action: params.btnAction,
        items: state.DrugReturnList,
        pk: params.id,
      },
      pk: params.id,
      extra: {
        division: controller.data.division,
      },
    });

    if (error) {
      SetErrorMessage(controller, { ...params, error: { error } });
    } else {
      const orderHistory = await GetDrugReturnRequestList(controller, { order: params.order });

      controller.setState({
        DrugReturnList: result?.items,
        OrderHistory: orderHistory,
        OrderReturnList: result,
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
      });
    }
  }
};

const HandleNewOrder: Handler = async (controller, params) => {
  const drugOrder = await DrugOrderDetailForAction.retrieve({
    pk: params.id,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });
  const drugOrderReturn = (drugOrder?.[0]?.items || [])?.map((item: any) => ({
    ...item,
    code: item.code,
    id: item.id,
    name: item.name,
    no: item.no,
    quantity_discard: 0,
    quantity_issue: item.quantity,
    quantity_left: item.left_quantity,
    quantity_request: null,
    quantity_restock: 0,
    reason: "",
    stock_unit_name: item.stock_unit_name,
  }));

  controller.setState({
    OrderReturnList: { ...drugOrder[0], allowed_actions: ["REQUEST"] },
    DrugReturnList: drugOrderReturn,
  });
};

/* ------------------------------------------------------ */

/*                          APIs                          */

/* ------------------------------------------------------ */
const GetDrugReturnRequestList: Handler = async (controller, params) => {
  const [result] = await DrugReturnRequestList.list({
    apiToken: controller.apiToken,
    params,
    extra: {
      division: controller.data.division,
    },
  });

  return result.items;
};

// Utils
const VerifyDrugsExceedIssuedQuantity: Handler = (controller, params) => {
  const state = controller.getState();

  const returnList = state.DrugReturnList || [];
  const orderHistory = state.OrderHistory || [];
  const drugs: string[] = [];

  for (const data of returnList) {
    const quantityIssue = Number(data.quantity_issue);

    let sum = 0;

    for (const order of orderHistory) {
      const items: any[] = order.items;
      const foundItem = items.find((item) => item.code === data.code);
      // * หากกำลังแก้ไข order ให้เอาข้อมูลจาก DrugReturnList
      const target = foundItem?.id === data.id ? data : foundItem;

      if (target && !["CANCELED", "REJECTED"].includes(order.status_name)) {
        sum += Number(target.quantity_request);
      }
    }

    if (params.action === "Request") {
      sum += Number(data.quantity_request);
    }

    if (sum > quantityIssue && quantityIssue) {
      drugs.push(data.name as string);
    }
  }

  if (drugs.length === 0) {
    return false;
  }

  const error = {
    error: {
      "ไม่อนุญาตให้คืนยาได้ เนื่องจากมีการแจ้งคืนยาไปครบตามจำนวนคงเหลือแล้ว": drugs,
    },
  };

  SetErrorMessage(controller, { ...params, error });

  return true;
};
